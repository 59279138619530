<template>
	<div>
		<v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
        <v-card class="pl-6 pr-6 pt-6">
			

			<v-row >
				<v-col sm="6" :md="dataInvoice.cabecera.participante.length > 20 ? 6 : 3" class="pt-0 pb-0" >
					<b>PROVEEDOR:</b> {{dataInvoice.cabecera.participante}}
				</v-col>
				<v-col sm="6" :md="dataInvoice.cabecera.participante.length > 20 ? 3 : 3" class="pt-0 pb-0" >
					<b>TIPO:</b> {{dataInvoice.cabecera.tipo_factura}}
				</v-col>
				<v-col sm="6" :md="dataInvoice.cabecera.participante.length > 20 ? 3 : 2" class="pt-0 pb-0" >
					<b>ESTADO:</b> {{dataInvoice.cabecera.estado}}
				</v-col>
				<v-col sm="6" :md="dataInvoice.cabecera.participante.length > 20 ? 3 : 4" class="pt-0 pb-0" >
					<b>FECHA:</b> {{dataInvoice.cabecera.invoice_date}}
				</v-col>
			</v-row>
			<v-row>
				<v-col md="12" sm="12">
					<v-data-table
						:headers="headItems"
						:items=dataInvoice.items
						:items-per-page="100"
						hide-default-footer
						class="elevation-1"
					>
					<template v-slot:item.total="{item}">
						{{item.total.toFixed(2)}}
					</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="3" sm="12" class="pt-0 offset-md-9">	 
					<v-col md="12" sm="12" class="pl-12 pt-10 ">
						<v-row>
							<v-col class="b-1 pt-2 pb-1 text-right" style="border-bottom: 1px solid #cecece">Sub total: <b>${{dataInvoice.totales.subTotal}}</b></v-col>
						</v-row>
						<v-row>
							<v-col class="pt-4 pb-1 text-right " style="border-bottom: 1px solid #cecece">Sub total IVA 12%: <b>${{dataInvoice.totales.subTotalConIva}}</b></v-col>
						</v-row>
						<v-row>
							<v-col class="pt-4 pb-1 text-right" style="border-bottom: 1px solid #cecece">Sub total IVA 0%: <b>${{dataInvoice.totales.subTotalSinIva}}</b></v-col>
						</v-row>
						<v-row>
							<v-col class="pt-4 pb-1 text-right" style="border-bottom: 1px solid #cecece">IVA: <b>${{dataInvoice.totales.totalIVA}}</b></v-col>
						</v-row>
						<v-row>
							<v-col class="pt-4 pb-1 text-right" style="border-bottom: 1px solid #cecece">Total: <b>${{dataInvoice.totales.total}}</b></v-col>
						</v-row>
					</v-col>
				</v-col>
			</v-row>
        </v-card>
	</div>
</template>
<script>
import {mapState, mapMutations, mapActions} from 'vuex'

export default {
	name: "InvoiceFormComponent",
	 
	data: ()=> ({
		headItems: [
			{ text: 'No.', value: 'secId' , align:"start"},
			{ text: 'Descripcion', value:'description' , align:"start"},
			{ text: 'Cantidad', value: 'cantidad' , align:"end"},
			{ text: 'Precio', value: 'valorUnitario' , align:"end"},
			{ text: '%IVA', value: 'pctImp' , align:"end"},
			{ text: 'Total', value: 'total', align:"end"},
		],
		overlay:false,
		dataInvoice:{
			cabecera:{
				participante:''
			},
			items:[],
			totales:{}
		}
    }),
    computed: {

    	...mapState('master',['loadingTable','user','tenantId']),

    },
    methods: {

    	...mapMutations('master',['setUrl','setMenu','setTitleToolbar']),
	   	
	    ...mapActions('master',['requestApi']),
	    
		formInvoice() {
			this.overlay=true
            this.setUrl(`lista-inventario-caducado-por-caducar/${this.$route.params.invoiceId}`)
            this.requestApi({
                method : 'GET',
                data : {}
            }).then(res=>{
            				        			    		
				console.log(res)
				this.dataInvoice = res.data
				
				this.setTitleToolbar(`FACTURA DE COMPRA ${this.dataInvoice.cabecera.invoice_number}`)
				
            }).catch(()=>{
            	
					  
            }).then(()=>{
				this.overlay= false
			});
            
            
        },
    
        
        abrirFormPago() {
        	this.formPago = true
        },
        
	},
	       

    mounted() {
    	
		
    	this.formInvoice(this.$route.params.invoiceId)
    	 
    },
    
  }

</script>